define([], function() {

  const emailSignUpSuccess = function() {
    const component = {};

    const _config = {
      selectors: {
        emailSubmitSuccess: '.widgetWaitList_signUpSuccessful',
        emailSignUpForm: '.widgetWaitList_signUpForm'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.emailSubmitSuccess = component.element.querySelector(component.config.selectors.emailSubmitSuccess);
      component.emailSignUpForm = component.element.querySelector(component.config.selectors.emailSignUpForm);
      component.bind();
      component.setNoValidate();
      return component;
    };

    const _setNoValidate = () => {
      component.emailSignUpForm.noValidate = true;
    };

    const _bind = () => {
      if(component.emailSubmitSuccess) {
        window.dataLayer.push({
          event: 'customEvent',
          eventData: {
            eventCategory: 'newsletter',
            eventAction: 'successful sign-up',
            eventLabel: '',
            eventPage: ''
          }
        });
      }
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.setNoValidate = _setNoValidate;
    return component;
  };

  return emailSignUpSuccess;
});
